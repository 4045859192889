.App{
  position: relative;
  overflow: clip;
}
.App>:nth-child(1){
  background:var(--black);
  position: relative;
}
.white-gradient{
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: rgba(255, 255, 255, 0.522);
   filter: blur(100px);
   border-radius: 100px;
}